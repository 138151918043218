import React from 'react'
import './style.less';
import { Row, Col } from 'antd';
import ReactHtmlParser from 'react-html-parser';

class PageTitle extends React.Component {

    render(){

        return (
            <div className="page-title" style={{backgroundImage: "url('" + this.props.image +"')"}}>
                <Row>
                    <Col xs={{span: 24}}>
                        <h1>{ReactHtmlParser(this.props.title)}</h1>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PageTitle